import React from 'react';
import './MainNavigator.css'
import {Link, NavLink} from 'react-router-dom';

function MainNavigator() {
  const navStyle = {
    color: 'white'
  };

  return (
    <div  className="center">
      <nav className="menu">
          <h1 className="menu__logo1">Tune ITCon</h1>
          <div className="menu__right">
          <ul className="menu__list">
            <li className="menu__list-item">
            <NavLink className="menu__link" activeClassName="menu__list-item--active" exact to='/'>Home</NavLink>
            </li>
            <li className="menu__list-item">
            <NavLink className="menu__link" activeClassName="menu__list-item--active" exact to='/service'>Service</NavLink>
            </li>
            <li className="menu__list-item">
            <NavLink className="menu__link" activeClassName="menu__list-item--active" exact to='/about'>About Us</NavLink>
            </li>
            <li className="menu__list-item">
            <NavLink className="menu__link" activeClassName="menu__list-item--active" exact to='/contact'>Contact Us</NavLink>
            </li>
          </ul>
          </div>
      </nav>
    </div>
  );
}

export default MainNavigator;