import React from 'react';
import Typography from "@material-ui/core/Typography";

import '../Common.css';
import headlineStrip from "../images/headline-stripe.png";

function About() {
  return (
    <div>
      <section>
      <article className="article">
        <Typography paragraph>
          <h1 className="page-title">About Us</h1>
          <img src={headlineStrip} alt="headline strip" className="headline-indicator-image" align="left"/>
          <br/>
          <p className="para-gen-content">
            Our founders with 35 years of experience and Tune ITCon Limited provide professional services for a wide range of industries and enterprises around the world.<br/><br/>

            Our profound client-base and bespoke services<br/><br/>
            <h1 className="sub-para-title-1">Finance and Banking</h1>
            Deutsche Investment Trust, Deutsche Boerse/Stock Market, Dresdner Bank, Deutsche Bank, Alliance Group, Swiss Bank, Commerce Bank, Air Plus Business Credit Card, Bank of America Merrill Lynch, Credit Suisse, HSBC
            <br/><br/>
            <h1 className="sub-para-title-1">Insurance</h1>
            Alliance Group, Claimsnet<br/><br/>

            <h1 className="sub-para-title-1">Automobile</h1>
            EDS, Opel, General Motors<br/><br/>

            <h1 className="sub-para-title-1">Tourism, Travel and Aviation</h1>
            Thomas Cook, Neckermann Retail and Travel, Boeing<br/><br/>

            <h1 className="sub-para-title-1">Logistic, Supply Chains, Shipping and Delivery Services</h1>
            Deutsche Post, DHL, Federal Express <br/><br/>

            <h1 className="sub-para-title-1">Telecommunication and Mobile Services</h1>
            Deutsche Telekom, T-Mobile, Vodafone, Nokia<br/><br/>

            <h1 className="sub-para-title-1">Controlling / Consulting</h1>
            Accenture, IBM Professional Consultancy Services<br/><br/>

            <h1 className="sub-para-title-1">IT, Software and Tools Development</h1>
            IBM, Inprise, Borland, Hewlett Packard, EDS, Accenture IT Services<br/><br/>

            <h1 className="sub-para-title-1">Online Shops, Consumer Products, Electronics and Retail</h1>
            Procter & Gamble, Philips Consumer Electronics, Hertie / Karstadt (retail / shopping mall), Neckermann, Catenic / Bosch, 3 Pagen (OTTO) online shop, 3Suisses online shop<br/><br/>

            <h1 className="sub-para-title-1">Entertainment and News</h1>
            ZDF TV station, Nacama ISP, T-Online Navigation<br/><br/>

            <h1 className="sub-para-title-1">Search Engine Services, Location and Maps</h1>
            Navteq online map and navigation, here.com, Nokia<br/><br/>

            <h1 className="sub-para-title-1">Public Sector</h1>
            ZIVIT German Government IT Department, Home Office UK<br/><br/>

            <h1 className="sub-para-title-1">We bring our long 35 years of enterprise experience to your business</h1><br/>
            We believe that startups, micro, small and medium-size business can benefit from service providers like us who have extensive expertise and skills in large enterprises.<br/>
            We provide professional and top-quality services that are affordable and optimally tailored to the growth of your business.<br/><br/></p>
        </Typography>
      </article>
    </section>
    </div>
  );
}

export default About;