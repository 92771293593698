import React from 'react';
import Typography from "@material-ui/core/Typography";
import '../Common.css';
import headlineStrip from "../images/headline-stripe.png";

function Contact() {
  return (
    <div id="contactId">
        <section>
            <article className="article">
                <Typography paragraph>
                    <h1 className="page-title">Contact Us</h1>
                    <img src={headlineStrip} alt="headline strip" className="headline-indicator-image" align="left"/>
                    <br/>
                    <p className="para-gen-content">

                Tune ITCon Ltd <br/>
                D S House<br/>
                306 High Street<br/>
                CR0 1NG Croydon<br/>
                Surrey<br/>
                United Kingdom<br/><br/>

                Marketing: <a className="email-link" href="mailto:marketing@tuneitcon.com">marketing@tuneitcon.com</a><br/>
                Sales: <a className="email-link" href="mailto:sales@tuneitcon.com">sales@tuneitcon.com</a><br/>
                Support: <a className="email-link" href="mailto:suport@tuneitcon.com">suport@tuneitcon.com</a><br/>
                GDPR: <a className="email-link" href="mailto:gdpr@tuneitcon.com">gdpr@tuneitcon.com</a><br/>
                HR: <a className="email-link" href="mailto:hr@tuneitcon.com">hr@tuneitcon.com</a><br/>
                For all other queries: <a className="email-link" href="mailto:customerservice@tuneitcon.com">customerservice@tuneitcon.com</a><br/></p>

            </Typography>
            </article>
        </section>
    </div>
  );
}

export default Contact;